import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import {
  Grid, MenuItem,
  ListItemIcon, Typography,
  MenuList, Box, Hidden,
  Collapse,
} from '@material-ui/core';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {withStyles, makeStyles} from '@material-ui/core/styles';
import AssignmentIcon from '@material-ui/icons/Assignment';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import DashboardIcon from '@material-ui/icons/Dashboard';
import Layout from './Dashboard';
import imgLogo from '../img/dashhboard/logo-w_1.png';

const StyledMenuItem = withStyles((theme) => ({
  root: {
    color: '#fff',
    '& .MuiListItemIcon-root': {
      color: '#fff',
    },
    '&:hover': {
      '&:focus': {
        backgroundColor: theme.palette.common.white,
      },
    },
    '&:focus': {
      borderTopLeftRadius: 12,
      borderBottomLeftRadius: 12,
      backgroundColor: theme.palette.common.white,
      color: 'black',
      '& .MuiListItemIcon-root': {
        color: '#3F51B5',
      },
    },
  },
}))(MenuItem);

const useStyle = makeStyles((theme) => ({
  menuItemTypo: {
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    fontSize: '21px',
    [theme.breakpoints.down('lg')]: {fontSize: '16px'},
    [theme.breakpoints.down('md')]: {fontSize: '14px'},
  },
  drawerPaper: {
    width: 240,
    backgroundColor: '#144999',
  },
}));

const BackofficeDashboard = ({
  children, history, backoffice, idProject,
}) => {
  const [openSubMenu, setOpenSubMenu] = useState(false);
  const drawerMenu = (
    <Box style={{maxHeight: '100vh', overflowY: 'auto'}}>
      <Link to="/dashboard">
        <Box
          component="img"
          src={imgLogo}
          alt="logo"
          style={{
            zIndex: 1, marginTop: 'auto', marginBottom: 'auto',
          }}
        />
      </Link>
      <MenuList
        autoFocusItem
        style={{
          marginLeft: '5%',
        }}
      >
        <StyledMenuItem
          selected={window.location.pathname === `/dashboard/project/${idProject}/backoffice`}
          onClick={() => history.push(`/dashboard/project/${idProject}/backoffice`)}
        >
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <Typography variant="inherit" className={useStyle().menuItemTypo}>
            Dashboard
          </Typography>
        </StyledMenuItem>
        <StyledMenuItem
          selected={window.location.pathname === `/dashboard/project/${idProject}/backoffice/user`}
          onClick={() => history.push(`/dashboard/project/${idProject}/backoffice/user`)}
        >
          <ListItemIcon>
            <PeopleAltIcon />
          </ListItemIcon>
          <Typography variant="inherit" className={useStyle().menuItemTypo}>
            Usuarios
          </Typography>
        </StyledMenuItem>
        <StyledMenuItem
          selected={window.location.pathname === `/dashboard/project/${idProject}/backoffice/notifications`}
          onClick={() => history.push(`/dashboard/project/${idProject}/backoffice/notifications`)}
        >
          <ListItemIcon>
            <PeopleAltIcon />
          </ListItemIcon>
          <Typography variant="inherit" className={useStyle().menuItemTypo}>
            Notificaciones
          </Typography>
        </StyledMenuItem>
        <StyledMenuItem
          selected={openSubMenu}
          onClick={() => setOpenSubMenu(!openSubMenu)}
        >
          <ListItemIcon>
            <AssignmentIcon />
          </ListItemIcon>
          <Typography variant="inherit" className={useStyle().menuItemTypo}>
            Colecciones
          </Typography>
          <Box
            style={{
              width: '30%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {openSubMenu ? (
              <ExpandLessIcon />
            ) : (
              <ExpandMoreIcon />
            )}
          </Box>
        </StyledMenuItem>
        <Collapse in={openSubMenu} style={{backgroundColor: '#083567', padding: '0px 10px'}}>
          {
          backoffice.databases.map((database) => (
            <StyledMenuItem
              selected={window.location.pathname === `/dashboard/project/${idProject}/backoffice/${database.name}`}
              onClick={() => history.push(`/dashboard/project/${idProject}/backoffice/${database.name}`)}
            >
              <Typography variant="inherit" className={useStyle().menuItemTypo} style={{fontSize: '16px', paddingLeft: '20px'}}>
                {database.label}
              </Typography>
            </StyledMenuItem>
          ))
          }
        </Collapse>
      </MenuList>
    </Box>
  );

  return (
    <Box
      component="div"
      style={{
        display: 'flex',
        height: '100vh',
      }}
    >
      <Grid container>
        <Hidden smDown>
          <Grid item md={2} style={{backgroundColor: '#144999'}}>
            {drawerMenu}
          </Grid>
        </Hidden>
        <Grid className="p-3" item sm={12} md={10} xs={12} style={{backgroundColor: '#F3F3F3'}}>
          <Layout data={drawerMenu}>
            {children}
          </Layout>
        </Grid>
      </Grid>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  backoffice: state.backoffice.data,
});

export default connect(mapStateToProps)(BackofficeDashboard);
