import React from 'react';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import {Box} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';

const useStyle = makeStyles(() => ({
  mainBox: {
    margin: '10px',
    borderRadius: '8px',
    overflow: 'hidden',
    boxShadow: '0 1px 3px rgba(0, 0, 0, 0.12)',
  },
  topSpace: {
    height: '60px',
    backgroundColor: '#ffffff',
  },
  headerContainer: {
    backgroundColor: '#2B579A',
    padding: '20px',
    display: 'flex',
    alignItems: 'center',
  },
  avatarSection: {
    marginRight: '16px',
  },
  avatar: {
    width: '48px',
    height: '48px',
    backgroundColor: '#E8EFF9',
    color: '#2B579A',
    fontSize: '1.25rem',
    fontWeight: '500',
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  nameText: {
    color: '#FFFFFF',
    fontSize: '1.125rem',
    fontWeight: 500,
    margin: 0,
    lineHeight: '1.4',
  },
  roleText: {
    color: '#E8EFF9',
    fontSize: '0.875rem',
    margin: '4px 0 0 0',
    fontWeight: '400',
  },
  buttonContainer: {
    padding: '16px',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  buttonAccept: {
    backgroundColor: '#21154A',
    color: '#FFFFFF',
    padding: '6px 16px',
    fontSize: '0.875rem',
    textTransform: 'none',
    borderRadius: '4px',
    '&:hover': {
      backgroundColor: 'rgba(43, 87, 154, 0.04)',
    },
  },
  buttonDelete: {
    backgroundColor: '#FFFFFF',
    color: '#2B579A',
    padding: '6px 16px',
    fontSize: '0.875rem',
    textTransform: 'none',
    border: '1px solid #2B579A',
    borderRadius: '4px',
    '&:hover': {
      backgroundColor: 'rgba(43, 87, 154, 0.04)',
    },
  },
}));

const CardUser = ({
  text, name, permission, isAdmin, status,
  handleRemove, handleAccept,
}) => {
  const classes = useStyle();
  return (
    <Box className={classes.mainBox}>
      <Paper elevation={0}>
        <div className={classes.topSpace} />
        <div className={classes.headerContainer}>
          <Box component="div" className={classes.avatarSection}>
            <Avatar className={classes.avatar}>
              {text}
            </Avatar>
          </Box>
          <Box component="div" className={classes.textContainer}>
            <h4 className={classes.nameText}>{name}</h4>
            <p className={classes.roleText}>{permission}</p>
          </Box>
        </div>
        <div className={classes.buttonContainer}>
          <Button
            onClick={handleRemove}
            className={classes.buttonDelete}
          >
            ELIMINAR
          </Button>
          {!isAdmin && status === 'waiting' && (
            <Button
              onClick={handleAccept}
              className={classes.buttonAccept}
            >
              ACEPTAR
            </Button>
          )}
        </div>
      </Paper>
    </Box>
  );
};

export default CardUser;
