import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Thumbnail from 'react-webpage-thumbnail';
import {Box, Button, Hidden} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  mainBox: {
    borderRadius: '4px',
    border: '1px solid var(--gray-divider, #E5E5E5)',
    background: 'var(--white-high-emphasis, #FFF)',
    width: '78%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    [theme.breakpoints.down('600')]: {
      width: '100%',
    },
  },
  boxTemplateContainer: {
    border: '1px solid #E2E2E2',
    backgroundColor: '#FCFCFC',
    width: '95%',
    display: 'flex',
    flexDirection: 'row',
    padding: '10px',
    margin: '20px',
    [theme.breakpoints.down('550')]: {
      flexDirection: 'column',
    },
  },
  templateIconAndTitle: {
    width: '70%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    [theme.breakpoints.down('550')]: {
      width: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
    },
  },
  imageStyle: {
    width: 'auto',
    position: 'relative',
    [theme.breakpoints.down('800')]: {
      width: '150px',
    },
    [theme.breakpoints.down('700')]: {
      width: '120px',
    },
    [theme.breakpoints.down('550')]: {
      width: 'auto',
    },
  },
  textSection: {
    marginLeft: '10px',
    [theme.breakpoints.down('550')]: {
      textAlign: 'center',
    },
  },
  titleTemplate: {
    color: '#292151',
    fontSize: '21px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: 'normal',
    [theme.breakpoints.down('900')]: {
      fontSize: '18px',
    },
  },
  subtitleTemplate: {
    color: '#6E7A90',
    fontSize: '17px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
    [theme.breakpoints.down('900')]: {
      fontSize: '14px',
    },
  },
  descriptionTemplate: {
    color: '#A3A3A3',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
    [theme.breakpoints.down('900')]: {
      fontSize: '11px',
    },
  },
  divisor: {
    backgroundColor: '#CACACA',
    width: '1px',
  },
  optionsArea: {
    width: '29%',
    display: 'flex',
    padding: '10px',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('550')]: {
      width: '100%',
    },
  },
  buttonPreviewDemo: {
    backgroundColor: 'transparent',
    color: '#292151',
    fontWeight: 700,
    fontSize: '12px',
    border: '1px solid #292151',
    borderRadius: '10px',
    width: '150px',
    marginTop: '15px',
    [theme.breakpoints.down('900')]: {
      fontSize: '10px',
      width: '100px',
    },
  },
  buttonBuyTemplate: {
    backgroundColor: '#292151',
    color: '#FFF',
    fontWeight: 700,
    fontSize: '11px',
    borderRadius: '10px',
    width: '150px',
    marginTop: '10px',
    [theme.breakpoints.down('900')]: {
      fontSize: '10px',
      width: '100px',
    },
  },
}));

export default function TemplateListData({data, handlePreview, handlePurchase}) {
  const classes = useStyles();
  return (
    <Box className={classes.mainBox}>
      {
        data.map((index) => (
          <Box key={index._id} component="div" className={classes.boxTemplateContainer}>
            <Box component="div" className={classes.templateIconAndTitle}>
              <Box className={classes.imageStyle}>
                <Thumbnail url={`https://${index.slug}.loftyapps.website`} width={200} height={150} />
              </Box>
              <Box component="div" className={classes.textSection}>
                <Typography className={classes.titleTemplate}>
                  {index.name}
                </Typography>
                <Typography className={classes.subtitleTemplate}>
                  {index?.category}
                </Typography>
                <Typography className={classes.descriptionTemplate}>
                  {index?.description}
                </Typography>
              </Box>
            </Box>
            <Hidden xsDown>
              <Box component="div" className={classes.divisor} />
            </Hidden>
            <Box component="div" className={classes.optionsArea}>
              <Typography className={classes.titleTemplate}>
                {`HNL ${index?.price?.toFixed(2)}`}
              </Typography>
              <Button
                className={classes.buttonPreviewDemo}
                onClick={() => {
                  handlePreview(index.slug);
                }}
              >
                VER DEMO
              </Button>
              <Button
                className={classes.buttonBuyTemplate}
                onClick={() => handlePurchase(index._id)}
              >
                COMPRAR PLANTILLA
              </Button>
            </Box>
          </Box>
        ))
      }
    </Box>
  );
}
