/* eslint-disable array-callback-return */
import React from 'react';
import {withStyles, makeStyles} from '@material-ui/core/styles';
import {
  Grid, MenuItem,
  Typography,
  MenuList,
  Box,
} from '@material-ui/core';
// import DeveloperBoardIcon from '@material-ui/icons/DeveloperBoard';

const useStyle = makeStyles(() => ({
  iconTitle: {
    borderRadius: '15px',
    height: '30px',
    width: '60px',
    backgroundColor: '#fff',
    fontSize: '10px',
    fontWeight: '700',
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    marginRight: '5px',
  },
}));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    fontSize: '21px',
    color: 'rgba(41, 33, 81, 1)',
    '& .MuiListItemIcon-root': {
      color: '#fff',
    },
    '&:hover': {
      backgroundColor: 'rgba(196, 196, 196, 1)',
      borderTopLeftRadius: 12,
      borderBottomLeftRadius: 12,
      color: 'black',
      '& .MuiListItemIcon-root': {
        color: '#3F51B5',
      },
      '&:focus': {
        backgroundColor: 'rgba(196, 196, 196, 1)',
      },
    },
    '&:focus': {
      borderTopLeftRadius: 12,
      borderBottomLeftRadius: 12,
      backgroundColor: 'rgba(155, 155, 155, 1)',
      color: 'black',
      '& .MuiListItemIcon-root': {
        color: '#3F51B5',
      },
    },
    [theme.breakpoints.down('lg')]: {fontSize: '16px'},
    [theme.breakpoints.down('md')]: {fontSize: '12px'},
  },
}))(MenuItem);

const DocumentationLayout = ({
  children, idProject, idDatabase,
}) => {
  // console.log(children);
  // eslint-disable-next-line array-callback-return
  function scrollToSection(element) {
    const elementIdentifier = document.getElementById(element);
    if (elementIdentifier) {
      // eslint-disable-next-line max-len
      // history.push(`/dashboard/project/${idProject}/database/${idDatabase}/documentation/#${element}`);
      elementIdentifier.scrollIntoView({behavior: 'smooth'});
    }
  }
  const filterNonExistingData = children.filter((index) => index !== undefined && index.props);
  const classes = useStyle();
  const drawerMenu = (
    <Box>
      <MenuList
        autoFocusItem
        style={{
          marginLeft: '4%',
        }}
      >
        {filterNonExistingData.map((index) => (
          <StyledMenuItem
            selected={window.location.pathname === `/dashboard/project/${idProject}/database/${idDatabase}/documentation/#${index.props.id}`}
            onClick={() => scrollToSection(index.props.id)}
          >
            {index.props.id === 'Create' && (
              <>
                <Box className={classes.iconTitle} style={{border: '2px solid rgba(32, 21, 73, 1)', color: 'rgba(32, 21, 73, 1)'}}>
                  POST
                </Box>
                <Typography variant="inherit">
                  Agregar
                </Typography>
              </>
            )}
            {index.props.id === 'getOne' && (
              <>
                <Box className={classes.iconTitle} style={{border: '2px solid rgba(47, 129, 50, 1)', color: 'rgba(47, 129, 50, 1)'}}>
                  GET
                </Box>
                <Typography variant="inherit">
                  Capturar
                </Typography>
              </>
            )}
            {index.props.id === 'list' && (
              <>
                <Box className={classes.iconTitle} style={{border: '2px solid rgba(47, 129, 50, 1)', color: 'rgba(47, 129, 50, 1)'}}>
                  GET
                </Box>
                <Typography variant="inherit">
                  Listar
                </Typography>
              </>
            )}
            {index.props.id === 'delete' && (
              <>
                <Box className={classes.iconTitle} style={{border: '2px solid rgba(204, 51, 51, 1)', color: 'rgba(204, 51, 51, 1)'}}>
                  DELETE
                </Box>
                <Typography variant="inherit">
                  Eliminar
                </Typography>
              </>
            )}
            {index.props.id === 'Update' && (
              <>
                <Box className={classes.iconTitle} style={{border: '2px solid rgba(149, 80, 124, 1)', color: 'rgba(149, 80, 124, 1)'}}>
                  PUT
                </Box>
                <Typography variant="inherit">
                  Actualizar
                </Typography>
              </>
            )}
          </StyledMenuItem>
        ))}
      </MenuList>
    </Box>
  );

  const sectionList = filterNonExistingData.map((index) => (
    <section id={index.props.id}>
      {index}
    </section>
  ));

  return (
    <Box
      component="div"
      style={{
        display: 'flex',
        height: '70vh',
      }}
    >
      <Grid container>
        <Grid item md={2} style={{backgroundColor: 'rgba(250, 250, 250, 1)', position: 'relative'}}>
          {drawerMenu}
        </Grid>
        <Grid className="p-3" item sm={12} md={10} xs={12} style={{backgroundColor: '#F5F5F5', overflowY: 'scroll', height: '80vh'}}>
          {
            sectionList
          }
        </Grid>
      </Grid>
    </Box>
  );
};

export default DocumentationLayout;
