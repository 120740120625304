/* eslint-disable max-len */
import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {listUserProject} from 'api-lofty';
import {withStyles, makeStyles} from '@material-ui/core/styles';
import {
  Grid, MenuItem,
  ListItemIcon, Typography,
  MenuList, Box, Hidden,
} from '@material-ui/core';
import Layout from './Dashboard';
import imgLogo from '../img/dashhboard/logo-w_1.png';
import exitImg from '../img/icons/salir.svg';
import imgActivity1 from '../img/icons/actividad_w.svg';
import imgActivity2 from '../img/icons/actividad_b.svg';
import imgField1 from '../img/icons/campos_w.svg';
import imgField2 from '../img/icons/campos_b.svg';
import imgServices1 from '../img/icons/servicios_w.svg';
import imgServices2 from '../img/icons/servicios_b.svg';
import imgDoc1 from '../img/icons/documentacion_w.svg';
import imgDoc2 from '../img/icons/documentacion_b.svg';
import imgConfig1 from '../img/icons/configuracion_w.svg';
import imgConfig2 from '../img/icons/configuracion_b.svg';
// import imgPermission1 from '../img/icons/permisos_w.svg';
// import imgPermission2 from '../img/icons/permisos_b.svg';
import imgData1 from '../img/icons/datos_w.svg';
import imgData2 from '../img/icons/datos_b.svg';
import 'toastr/build/toastr.min.css';

const useStyle = makeStyles((theme) => ({
  exitItemTypo: {
    color: '#fff',
    fontWeight: 'bold',
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    fontSize: '18px',
    marginLeft: '7%',
    marginTop: '10%',
    [theme.breakpoints.down('lg')]: {fontSize: '13px'},
    [theme.breakpoints.down('md')]: {fontSize: '12px'},
  },
  fontItemsStyle: {
    fontSize: '21px',
    [theme.breakpoints.down('lg')]: {fontSize: '12px'},
    [theme.breakpoints.down('1280')]: {fontSize: '11px'},
    [theme.breakpoints.down('1100')]: {fontSize: '9px'},
    [theme.breakpoints.down('950')]: {fontSize: '12px'},
  },
  exitIconStyle: {
    zIndex: 1,
    marginTop: '40px',
    marginBottom: '10%',
    marginLeft: '15%',
    width: '50px',
    height: 'auto',
    [theme.breakpoints.down('lg')]: {width: '30px'},
  },
}));

const StyledMenuField = withStyles((theme) => ({
  root: {
    color: '#fff',
    '& .MuiListItemIcon-root': {
      background: `url(${imgField1})`,
      width: '20px',
      height: '20px',
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
    },
    '&:hover': {
      backgroundColor: theme.palette.common.white,
      borderTopLeftRadius: 12,
      borderBottomLeftRadius: 12,
      color: 'black',
      '& .MuiListItemIcon-root': {
        background: `url(${imgField2})`,
        width: '17px !important',
        height: '17px !important',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
      },
    },
    '&$selected': {
      backgroundColor: theme.palette.common.white,
      color: 'black',
      borderTopLeftRadius: 12,
      borderBottomLeftRadius: 12,
      '& .MuiListItemIcon-root': {
        background: `url(${imgField2})`,
        width: '17px !important',
        height: '17px !important',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
      },
    },

    [theme.breakpoints.down('lg')]: {
      '& .MuiListItemIcon-root': {
        width: '17px',
        height: '17px',
      },
    },

    [theme.breakpoints.down('md')]: {
      fontSize: '12px',
    },
  },
  selected: {},
}))(MenuItem);

const StyledMenuServices = withStyles((theme) => ({
  root: {
    color: '#fff',
    '& .MuiListItemIcon-root': {
      background: `url(${imgServices1})`,
      width: '20px',
      height: '20px',
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
    },
    '&:hover': {
      backgroundColor: theme.palette.common.white,
      borderTopLeftRadius: 12,
      borderBottomLeftRadius: 12,
      color: 'black',
      '& .MuiListItemIcon-root': {
        background: `url(${imgServices2})`,
        width: '17px !important',
        height: '17px !important',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
      },
    },
    '&$selected': {
      backgroundColor: theme.palette.common.white,
      color: 'black',
      borderTopLeftRadius: 12,
      borderBottomLeftRadius: 12,
      '& .MuiListItemIcon-root': {
        background: `url(${imgServices2})`,
        width: '17px !important',
        height: '17px !important',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
      },
    },

    [theme.breakpoints.down('lg')]: {
      '& .MuiListItemIcon-root': {
        width: '17px',
        height: '17px',
      },
    },

    [theme.breakpoints.down('md')]: {
      fontSize: '12px',
    },
  },
  selected: {},
}))(MenuItem);

const StyledMenuDoc = withStyles((theme) => ({
  root: {
    color: '#fff',
    '& .MuiListItemIcon-root': {
      background: `url(${imgDoc1})`,
      width: '20px',
      height: '20px',
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
    },
    '&:hover': {
      backgroundColor: theme.palette.common.white,
      borderTopLeftRadius: 12,
      borderBottomLeftRadius: 12,
      color: 'black',
      '& .MuiListItemIcon-root': {
        background: `url(${imgDoc2})`,
        width: '17px !important',
        height: '17px !important',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
      },
    },
    '&$selected': {
      backgroundColor: theme.palette.common.white,
      color: 'black',
      borderTopLeftRadius: 12,
      borderBottomLeftRadius: 12,
      '& .MuiListItemIcon-root': {
        background: `url(${imgDoc2})`,
        width: '17px !important',
        height: '17px !important',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
      },
    },

    [theme.breakpoints.down('lg')]: {
      '& .MuiListItemIcon-root': {
        width: '17px',
        height: '17px',
      },
    },

    [theme.breakpoints.down('md')]: {
      fontSize: '12px',
    },
  },
  selected: {},
}))(MenuItem);

// const StyledMenuPermission = withStyles((theme) => ({
//   root: {
//     color: '#fff',
//     '& .MuiListItemIcon-root': {
//       background: `url(${imgPermission1})`,
//       width: '20px',
//       height: '20px',
//       backgroundSize: 'contain',
//       backgroundRepeat: 'no-repeat',
//     },
//     '&:hover': {
//       backgroundColor: theme.palette.common.white,
//       borderTopLeftRadius: 12,
//       borderBottomLeftRadius: 12,
//       color: 'black',
//       '& .MuiListItemIcon-root': {
//         background: `url(${imgPermission2})`,
//         width: '17px !important',
//         height: '17px !important',
//         backgroundSize: 'contain',
//         backgroundRepeat: 'no-repeat',
//       },
//     },
//     '&$selected': {
//       backgroundColor: theme.palette.common.white,
//       color: 'black',
//       borderTopLeftRadius: 12,
//       borderBottomLeftRadius: 12,
//       '& .MuiListItemIcon-root': {
//         background: `url(${imgPermission2})`,
//         width: '17px !important',
//         height: '17px !important',
//         backgroundSize: 'contain',
//         backgroundRepeat: 'no-repeat',
//       },
//     },

//     [theme.breakpoints.down('lg')]: {
//       '& .MuiListItemIcon-root': {
//         width: '17px',
//         height: '17px',
//       },
//     },

//     [theme.breakpoints.down('md')]: {
//       fontSize: '12px',
//     },
//   },
//   selected: {},
// }))(MenuItem);

const StyledMenuConfig = withStyles((theme) => ({
  root: {
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    fontSize: '21px',
    color: '#fff',
    '& .MuiListItemIcon-root': {
      background: `url(${imgConfig1})`,
      width: '30px',
      height: '30px',
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
    },
    '&:hover': {
      backgroundColor: theme.palette.common.white,
      borderTopLeftRadius: 12,
      borderBottomLeftRadius: 12,
      color: 'black',
      '& .MuiListItemIcon-root': {
        background: `url(${imgConfig2})`,
        width: '17px !important',
        height: '17px !important',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
      },
    },
    '&$selected': {
      backgroundColor: theme.palette.common.white,
      color: 'black',
      borderTopLeftRadius: 12,
      borderBottomLeftRadius: 12,
      '& .MuiListItemIcon-root': {
        background: `url(${imgConfig2})`,
        width: '17px !important',
        height: '17px !important',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
      },
    },

    [theme.breakpoints.down('lg')]: {
      '& .MuiListItemIcon-root': {
        width: '17px',
        height: '17px',
      },
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '12px',
    },
  },
  selected: {},
}))(MenuItem);

const StyledMenuData = withStyles((theme) => ({
  root: {
    color: '#fff',
    '& .MuiListItemIcon-root': {
      background: `url(${imgData1})`,
      width: '20px',
      height: '20px',
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
    },
    '&:hover': {
      backgroundColor: theme.palette.common.white,
      borderTopLeftRadius: 12,
      borderBottomLeftRadius: 12,
      color: 'black',
      '& .MuiListItemIcon-root': {
        background: `url(${imgData2})`,
        width: '17px !important',
        height: '17px !important',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
      },
    },
    '&$selected': {
      backgroundColor: theme.palette.common.white,
      color: 'black',
      borderTopLeftRadius: 12,
      borderBottomLeftRadius: 12,
      '& .MuiListItemIcon-root': {
        background: `url(${imgData2})`,
        width: '17px !important',
        height: '17px !important',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
      },
    },

    [theme.breakpoints.down('lg')]: {
      '& .MuiListItemIcon-root': {
        width: '17px',
        height: '17px',
      },
    },

    [theme.breakpoints.down('md')]: {
      fontSize: '12px',
    },
  },
  selected: {},
}))(MenuItem);

const StyledMenuActivity = withStyles((theme) => ({
  root: {
    color: '#fff',
    '& .MuiListItemIcon-root': {
      background: `url(${imgActivity1})`,
      width: '20px',
      height: '20px',
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
    },
    '&:hover': {
      backgroundColor: theme.palette.common.white,
      borderTopLeftRadius: 12,
      borderBottomLeftRadius: 12,
      color: 'black',
      '& .MuiListItemIcon-root': {
        background: `url(${imgActivity2})`,
        width: '17px !important',
        height: '17px !important',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
      },
    },
    '&$selected': {
      backgroundColor: theme.palette.common.white,
      color: 'black',
      borderTopLeftRadius: 12,
      borderBottomLeftRadius: 12,
      '& .MuiListItemIcon-root': {
        background: `url(${imgActivity2})`,
        width: '17px !important',
        height: '17px !important',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
      },
    },

    [theme.breakpoints.down('lg')]: {
      '& .MuiListItemIcon-root': {
        width: '17px',
        height: '17px',
      },
    },

    [theme.breakpoints.down('md')]: {
      fontSize: '12px',
    },
  },
  selected: {},
}))(MenuItem);

const PrincipalDashboard = ({
  children, history, idProject,
  idDatabase, token,
}) => {
  // const [validAuth, setValidAuth] = useState(false);
  const classes = useStyle();
  useEffect(async () => {
    try {
      const res = await listUserProject({idProject, token});
      if (res.data.length > 0) {
        // setValidAuth(true);
      }
    } catch (error) {
      //
      // setValidAuth(false);
    }
  }, []);
  const DrawerMenu = (
    <Box>
      <Link to="/dashboard">
        <Box
          component="img"
          src={imgLogo}
          alt="logo"
          style={{
            zIndex: 1, marginTop: 'auto', marginBottom: 'auto',
          }}
        />
      </Link>
      <MenuList
        autoFocusItem
        style={{
          marginLeft: '4%',
        }}
      >
        <StyledMenuField
          selected={window.location.pathname === `/dashboard/project/${idProject}/database/${idDatabase}`}
          onClick={() => history.push(`/dashboard/project/${idProject}/database/${idDatabase}`)}
        >
          <ListItemIcon />
          <Typography className={classes.fontItemsStyle}>
            Campos
          </Typography>
        </StyledMenuField>
        <StyledMenuServices
          selected={window.location.pathname === `/dashboard/project/${idProject}/database/${idDatabase}/endpoints`}
          onClick={() => history.push(`/dashboard/project/${idProject}/database/${idDatabase}/endpoints`)}
        >
          <ListItemIcon />
          <Typography className={classes.fontItemsStyle}>
            Servicios
          </Typography>
        </StyledMenuServices>
        {/* {
              validAuth && (
                <StyledMenuPermission
                  selected={window.location.pathname === `/dashboard/project/${idProject}/database/${idDatabase}/permission`}
                  onClick={() => history.push(`/dashboard/project/${idProject}/database/${idDatabase}/permission`)}
                >
                  <ListItemIcon />
                  <Typography className={classes.fontItemsStyle}>
                    Permisos
                  </Typography>
                </StyledMenuPermission>
              )
            } */}
        <StyledMenuDoc
          selected={window.location.pathname === `/dashboard/project/${idProject}/database/${idDatabase}/documentation`}
          onClick={() => history.push(`/dashboard/project/${idProject}/database/${idDatabase}/documentation`)}
        >
          <ListItemIcon />
          <Typography className={classes.fontItemsStyle}>
            Documentación
          </Typography>
        </StyledMenuDoc>
        <StyledMenuData
          selected={window.location.pathname === `/dashboard/project/${idProject}/database/${idDatabase}/data`}
          onClick={() => history.push(`/dashboard/project/${idProject}/database/${idDatabase}/data`)}
        >
          <ListItemIcon />
          <Typography className={classes.fontItemsStyle}>
            Datos
          </Typography>
        </StyledMenuData>
        <StyledMenuActivity
          selected={window.location.pathname === `/dashboard/project/${idProject}/database/${idDatabase}/activities`}
          onClick={() => history.push(`/dashboard/project/${idProject}/database/${idDatabase}/activities`)}
        >
          <ListItemIcon />
          <Typography className={classes.fontItemsStyle}>
            Actividad
          </Typography>
        </StyledMenuActivity>
        <StyledMenuConfig
          selected={window.location.pathname === `/dashboard/project/${idProject}/database/${idDatabase}/bucketconfig`}
          onClick={() => history.push(`/dashboard/project/${idProject}/database/${idDatabase}/bucketconfig`)}
        >
          <ListItemIcon />
          <Typography className={classes.fontItemsStyle}>
            Configuraciones
          </Typography>
        </StyledMenuConfig>
      </MenuList>
      <Box
        component="div"
        sx={{
          width: '100%', display: 'flex', justifyContent: 'flex-start', position: 'absolute', alignItems: 'center', bottom: 1, p: 1, m: 1,
        }}
      >
        <Link to={`/dashboard/project/${idProject}/database`}>
          <Box
            component="img"
            src={exitImg}
            alt="logo"
            className={classes.exitIconStyle}
          />
        </Link>
        <Typography variant="inherit" className={classes.exitItemTypo}>
          Salir de Colección de Datos
        </Typography>
      </Box>
    </Box>
  );

  return (
    <Box
      component="div"
      style={{
        display: 'flex',
        height: '100vh',
      }}
    >
      <Grid container>
        <Hidden smDown>
          <Grid item md={2} style={{backgroundColor: '#144999', position: 'relative'}}>
            {DrawerMenu}
          </Grid>
        </Hidden>
        <Grid className="p-3" item sm={12} md={10} xs={12} style={{backgroundColor: '#F5F5F5'}}>
          <Layout data={DrawerMenu}>
            {children}
          </Layout>
        </Grid>
      </Grid>
    </Box>
  );
};

const mapStateToProps = (state) => state.auth;

export default connect(mapStateToProps)(PrincipalDashboard);
