/* eslint-disable no-underscore-dangle */
import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {makeStyles} from '@material-ui/core/styles';
import {
  Box, Typography, TextField,
  MenuItem,
} from '@material-ui/core';
import {
  getOneDatabase, getOneProject,
  createBucketDatabaseConfig, updateBucketDatabaseConfig,
  deleteDatabase, getBucketDatabaseConfig,
  updateDatabaseLabel,
} from 'api-lofty';
import toastr from 'toastr';
import Button from '@material-ui/core/Button';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import RemoveDBModal from '../components/RemoveDBModal';
import Layout from '../layouts/DatabaseDashboard';
import 'toastr/build/toastr.min.css';
import LoadingPage from './LoadingPage';

const useStyle = makeStyles((theme) => ({
  mainBox: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    backgroundColor: '#FFF',
    borderRadius: '4px',
    minHeight: '70vh',
    padding: '10px 25px',
  },
  sectionArea: {
    width: '100%',
    padding: '10px',
    minHeight: '200px',
  },
  subContainer: {
    width: '50%',
    padding: '10px',
  },
  labelContainer: {
    height: '250px',
  },
  miniNavbarMenuTitle: {
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    fontSize: '21px',
    fontWeight: 'bold',
    color: '#292151',
    [theme.breakpoints.down('lg')]: {fontSize: '20px'},
    [theme.breakpoints.down('md')]: {fontSize: '18px'},
  },
  subtitleMenu: {
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    fontSize: '18px',
    color: '#292151',
    [theme.breakpoints.down('lg')]: {fontSize: '20px'},
    [theme.breakpoints.down('md')]: {fontSize: '18px'},
  },
  mainTitle: {
    color: 'rgba(0, 0, 0, 1)',
    marginLeft: '10px',
    marginBottom: '15px',
    fontSize: '22px',
    fontWeight: 'bold',
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    [theme.breakpoints.down('lg')]: {fontSize: '17px'},
    [theme.breakpoints.down('md')]: {fontSize: '14px'},
    [theme.breakpoints.down('xs')]: {fontSize: '18px'},
  },
  inputStyles: {
    margin: '5px',
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#1345A6',
        borderWidth: '2px',
        color: '#1345A6',
      },
      '&:hover fieldset': {
        borderColor: '#1345A6',
        color: '#1345A6',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#1345A6',
        color: '#1345A6',
      },
    },
  },
  descriptionSection: {
    backgroundColor: 'rgba(232, 244, 253, 1)',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: 'auto',
    borderRadius: '10px',
    padding: '15px 20px',
    marginTop: '10px',
    marginBottom: '10px',
  },
  listItemRender: {
    backgroundColor: '#DEF1FB',
    borderRadius: '50px',
    width: '75px',
    minHeight: '30px',
    margin: '10px',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'center',
  },
  iconStyle: {
    position: 'absolute',
    top: '-15px',
    right: '1px',
    width: '20px',
    height: '20px',
    cursor: 'pointer',
  },
}));

const BucketConfig = ({history, match, token}) => {
  const {params} = match;
  const {idProject, idDatabase} = params;
  const [labelUrl, setLabelUrl] = useState('');
  const [alreadyConfigured, setAlreadyConfigured] = useState(false);
  const [loading, setLoading] = useState(true);
  const [bucketConfigData, setBucketConfigData] = useState([]);
  const [labelBucket, setLabelBucket] = useState('');
  const [typeofBucket, setTypeofBucket] = useState('');
  const [dataProject, setDataProject] = useState({});
  const [dataDatabase, setDataDatabase] = useState({});
  const [metadata, setMetadata] = useState([]);
  const [openDeletion, setOpenDeletion] = useState(false);
  const typesOfFile = [
    {name: 'Video', value: 'video'},
    {name: 'Imagen', value: 'image'},
    {name: 'Documentos', value: 'document'},
    {name: 'Estandar', value: 'default'},
  ];

  const extensionsImage = [
    {name: 'JPG', value: 'jpg'},
    {name: 'PNG', value: 'png'},
    {name: 'Bitmap', value: 'bmp'},
    {name: 'Gif', value: 'gif'},
  ];

  const extensionsVideo = [
    {name: 'MP4', value: 'mp4'},
    {name: 'WebM', value: 'webm'},
    {name: 'AVI', value: 'avi'},
  ];

  const extensionsDocument = [
    {name: 'Documento word', value: 'docx'},
    {name: 'Documento PDF', value: 'pdf'},
  ];

  const classes = useStyle();

  function expungeData(value) {
    setTypeofBucket(value);
    setMetadata({});
  }

  const seekData = async () => {
    try {
      const resProject = await getOneProject({token, idProject});
      const resDatabase = await getOneDatabase({token, _id: idDatabase});
      setLabelUrl(`proyectos/${resProject.project.name}/Colección de Datos/${resDatabase.data.label}/Configuracion de Almacenamiento`);
      setDataProject(resProject.project);
      setDataDatabase(resDatabase.data);
      setLabelBucket(resDatabase?.data?.label);
      const infoFromBucket = await getBucketDatabaseConfig({idDatabase, idProject, token});
      if (infoFromBucket?.data?.type) {
        setAlreadyConfigured(true);
        setTypeofBucket(infoFromBucket?.data?.type);
        setMetadata(infoFromBucket?.data?.bucketMetadata);
      }
      setBucketConfigData(infoFromBucket?.data);
    } catch (error) {
      // console.log(error);
    }
    setLoading(false);
  };
  useEffect(() => {
    seekData();
  }, []);

  if (loading) {
    return <LoadingPage />;
  }

  function handleMetadata(typeofEdit, value) {
    setMetadata((prev) => {
      if (typeofEdit === 'type') {
        return {...prev, type: value};
      }
      if (typeofEdit === 'fileExtensions') {
        const getFileExtensions = prev?.fileExtensions || [];
        const findExists = getFileExtensions?.findIndex((index) => (index === value));
        if (findExists === -1) {
          getFileExtensions.push(value);
          return {
            ...prev,
            fileExtensions: getFileExtensions,
          };
        }
      }
      if (typeofEdit === 'durationLimit') {
        return {
          ...prev,
          durationLimit: Number(value),
        };
      }
      if (typeofEdit === 'imageHeight') {
        return {
          ...prev,
          imageHeight: Number(value),
        };
      }
      if (typeofEdit === 'imageWidth') {
        return {
          ...prev,
          imageWidth: Number(value),
        };
      }
      return prev;
    });
  }

  async function handleSaveSettings() {
    //
    try {
      await updateDatabaseLabel({
        _id: dataDatabase._id,
        idProject,
        label: labelBucket,
        token,
      });
      if (dataDatabase?.isBucket) {
        if (alreadyConfigured) {
          await updateBucketDatabaseConfig({
            _id: bucketConfigData._id,
            bucketMetadata: metadata,
            idDatabase,
            idProject,
            label: 'bucketconfig',
          });
        } else {
          await createBucketDatabaseConfig({
            bucketMetadata: metadata,
            idDatabase,
            idProject,
            label: 'bucketconfig',
            token,
            type: typeofBucket,
          });
        }
      }
      toastr.options = {
        positionClass: 'toast-top-right',
        hideDuration: 300,
        timeOut: 6000,
      };
      toastr.clear();
      setTimeout(() => toastr.success('Se guardo la data exitosamente'), 300);
    } catch (error) {
      toastr.error('Error al guardar');
    }
  }

  async function removeFileExtension(value) {
    setMetadata((prev) => {
      const getFileExtensions = prev?.fileExtensions || [];
      const filterData = getFileExtensions?.filter((index) => (index !== value));
      return {
        ...prev,
        fileExtensions: filterData,
      };
    });
  }

  return (
    <Layout
      history={history}
      projectName={dataProject?.name}
      idDatabase={idDatabase}
      idProject={idProject}
    >
      <Typography component="h5" variant="h5" className={classes.mainTitle}>
        {labelUrl}
      </Typography>
      <Box className={classes.descriptionSection}>
        <ErrorOutlineIcon style={{color: 'rgba(20, 86, 158, 1)'}} />
        <Typography
          style={{
            color: 'rgba(54, 54, 54, 1)', fontWeight: 400, fontSize: '14px', marginLeft: '20px', marginRight: '20px',
          }}
        >
          Configuraciones para los archivos de la coleccion de datos
        </Typography>
      </Box>
      <Box className={classes.mainBox}>
        <Box className={classes.sectionArea}>
          <Typography className={classes.miniNavbarMenuTitle}>
            Etiqueta
          </Typography>
          <div className="mt-4">
            <TextField
              name="label"
              type="text"
              label="Etiqueta"
              variant="outlined"
              value={labelBucket}
              onChange={(e) => setLabelBucket(e.target.value)}
              className={classes.inputStyles}
              inputProps={{
                classes: {
                  root: classes.cssOutlinedInput,
                  focused: classes.cssFocused,
                  notchedOutline: classes.notchedOutline,
                },
              }}
            />
          </div>
        </Box>
        {dataDatabase?.isBucket && (
          <Box className={classes.sectionArea} style={{display: 'flex', flexDirection: 'row'}}>
            <Box className={classes.subContainer}>
              <Box>
                <Typography className={classes.miniNavbarMenuTitle}>
                  Almacenamiento de Archivos
                </Typography>
                <Typography className={classes.subtitleMenu}>
                  Tipo de Archivos
                </Typography>
                <div className="my-4">
                  <TextField
                    onChange={(e) => expungeData(e.target.value)}
                    value={typeofBucket}
                    select
                    fullWidth
                    name="type"
                    type="text"
                    label="Tipo"
                    variant="outlined"
                    className={classes.inputStyles}
                    disabled={alreadyConfigured}
                  >
                    {
                  typesOfFile.map(({name, value}) => (
                    <MenuItem value={value} key={value}>
                      {name}
                    </MenuItem>
                  ))
                }
                  </TextField>
                </div>
              </Box>
            </Box>
            <Box className={classes.subContainer}>
              <Box>
                {
              typeofBucket === 'image' && (
                <Box>
                  <Box style={{height: '45px'}} />
                  <Typography className={classes.subtitleMenu}>
                    Configuracion de imagen
                  </Typography>
                  <TextField
                    name="height"
                    type="number"
                    label="Altura"
                    style={{width: '45%'}}
                    variant="outlined"
                    value={metadata?.imageHeight}
                    className={classes.inputStyles}
                    onChange={(e) => handleMetadata('imageHeight', e.target.value)}
                    inputProps={{
                      classes: {
                        root: classes.cssOutlinedInput,
                        focused: classes.cssFocused,
                        notchedOutline: classes.notchedOutline,
                      },
                    }}
                  />
                  <TextField
                    name="width"
                    type="number"
                    style={{width: '45%'}}
                    label="Anchura"
                    variant="outlined"
                    value={metadata?.imageWidth}
                    className={classes.inputStyles}
                    onChange={(e) => handleMetadata('imageWidth', e.target.value)}
                  />
                  <div className="my-4">
                    <TextField
                      onChange={(e) => handleMetadata('fileExtensions', e.target.value)}
                      select
                      fullWidth
                      name="fileExtensions"
                      type="text"
                      label="Tipo"
                      variant="outlined"
                      className={classes.inputStyles}
                    >
                      {
                    extensionsImage.map(({name, value}) => (
                      <MenuItem value={value} key={value}>
                        {name}
                      </MenuItem>
                    ))
                    }
                    </TextField>
                    <Box
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                        width: '100%',
                        justifyContent: 'flex-end',
                      }}
                    >
                      {
                        metadata?.fileExtensions?.map((index) => (
                          <Box className={classes.listItemRender}>
                            <Box
                              className={classes.iconStyle}
                              onClick={() => removeFileExtension(index)}
                            >
                              x
                            </Box>
                            <Box>
                              {index}
                            </Box>
                          </Box>
                        ))
                      }
                    </Box>
                  </div>
                </Box>
              )
            }
                {
              typeofBucket === 'video' && (
                <Box>
                  <Box style={{height: '45px'}} />
                  <Typography className={classes.miniNavbarMenuTitle}>
                    Configuracion de video
                  </Typography>
                  <TextField
                    name="time"
                    type="number"
                    style={{width: '50%'}}
                    label="Longitud de video (segundos)"
                    value={metadata?.durationLimit}
                    variant="outlined"
                    className={classes.inputStyles}
                    onChange={(e) => handleMetadata('durationLimit', e.target.value)}
                  />
                  <div className="my-4">
                    <TextField
                      onChange={(e) => handleMetadata('fileExtensions', e.target.value)}
                      select
                      fullWidth
                      name="fileExtensions"
                      type="text"
                      label="Tipo"
                      variant="outlined"
                      className={classes.inputStyles}
                    >
                      {
                    extensionsVideo.map(({name, value}) => (
                      <MenuItem value={value} key={value}>
                        {name}
                      </MenuItem>
                    ))
                    }
                    </TextField>
                    <Box
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                        width: '100%',
                        justifyContent: 'flex-end',
                      }}
                    >
                      {
                        metadata?.fileExtensions?.map((index) => (
                          <Box className={classes.listItemRender}>
                            <Box
                              className={classes.iconStyle}
                              onClick={() => removeFileExtension(index)}
                            >
                              x
                            </Box>
                            <Box>
                              {index}
                            </Box>
                          </Box>
                        ))
                      }
                    </Box>
                  </div>
                </Box>
              )
            }
                {
              typeofBucket === 'document' && (
                <Box>
                  <Box style={{height: '45px'}} />
                  <Typography className={classes.miniNavbarMenuTitle}>
                    Configuracion de Documento
                  </Typography>
                  <div className="my-4">
                    <TextField
                      onChange={(e) => handleMetadata('fileExtensions', e.target.value)}
                      select
                      fullWidth
                      name="fileExtensions"
                      type="text"
                      label="Tipo"
                      variant="outlined"
                      className={classes.inputStyles}
                    >
                      {
                    extensionsDocument.map(({name, value}) => (
                      <MenuItem value={value} key={value}>
                        {name}
                      </MenuItem>
                    ))
                    }
                    </TextField>
                    <Box
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                        width: '100%',
                        justifyContent: 'flex-end',
                      }}
                    >
                      {
                        metadata?.fileExtensions?.map((index) => (
                          <Box className={classes.listItemRender}>
                            <Box
                              className={classes.iconStyle}
                              onClick={() => removeFileExtension(index)}
                            >
                              x
                            </Box>
                            <Box>
                              {index}
                            </Box>
                          </Box>
                        ))
                      }
                    </Box>
                  </div>
                </Box>
              )
            }
              </Box>
            </Box>
          </Box>
        )}
        <Box style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            style={{
              color: '#fff',
              fontWeight: 'bold',
              margin: '10px',
              backgroundColor: '#292151',
              fontFamily: [
                'Nunito',
                'Roboto',
                'Helvetica Neue',
                'Arial',
                'sans-serif',
              ].join(','),
              textTransform: 'none',
            }}
            onClick={() => setOpenDeletion(true)}
          >
            Eliminar Coleccion
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="large"
            style={{
              margin: '10px',
              color: '#fff',
              fontWeight: 'bold',
              backgroundColor: '#29B2EF',
              fontFamily: [
                'Nunito',
                'Roboto',
                'Helvetica Neue',
                'Arial',
                'sans-serif',
              ].join(','),
              textTransform: 'none',
            }}
            onClick={() => handleSaveSettings()}
          >
            Actualizar Cambios
          </Button>
        </Box>
      </Box>
      <Box>
        <RemoveDBModal
          open={openDeletion}
          onClose={() => setOpenDeletion(!openDeletion)}
          undoDelete={() => setOpenDeletion(!openDeletion)}
          dbName={dataDatabase?.label}
          handleDeletion={async () => {
            try {
              await deleteDatabase({_id: idDatabase, token});
              toastr.options = {
                positionClass: 'toast-top-right',
                hideDuration: 300,
                timeOut: 6000,
              };
              setOpenDeletion(!openDeletion);
              toastr.clear();
              setTimeout(() => toastr.success('Se elimino exitosamente la tabla'), 300);
              history.push(`/dashboard/project/${idProject}`);
            } catch (error) {
              if (error.info) {
                toastr.options = {
                  positionClass: 'toast-top-right',
                  hideDuration: 300,
                  timeOut: 6000,
                };
                toastr.clear();
                setTimeout(() => toastr.error(`error: \n ${error.info}`), 300);
              } else {
                toastr.options = {
                  positionClass: 'toast-top-right',
                  hideDuration: 300,
                  timeOut: 6000,
                };
                toastr.clear();
                setTimeout(() => toastr.error('error: \n No se elimino la tabla'), 300);
              }
            }
          }}
        />
      </Box>
    </Layout>
  );
};

const mapStateToProps = (state) => state.auth;

export default connect(mapStateToProps)(BucketConfig);
